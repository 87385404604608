import React from "react"
import { Link, graphql } from "gatsby"
import styled from 'styled-components';

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import i18n from "../i18n";

const Date = styled.p`
  &&& {
    margin-top: 10px;
    font-size: 15px;
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  color: #777;
`
const Header = styled.header`
  &&& {
    margin-bottom: -4px;
  }
`

const BlogIndex = ({ data, location, pageContext }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes
  const { langKey } = pageContext
  const currentLangDict = i18n[langKey]

  if (posts.length === 0) {
    return (
      <Layout location={location} title={currentLangDict[siteTitle]} langKey={langKey}>
        <SEO title="All posts" />
        <Bio langKey={langKey} />
        <p>
          Sorry, currently there are no posts in English. <br />
          There will be some in the future. 
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={currentLangDict[siteTitle]} langKey={langKey}>
      <SEO title="All posts" />
      <Bio langKey={langKey} />
      <ol style={{ listStyle: `none`, paddingLeft: 0 }}>
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug
          return (
            <li key={post.fields.slug}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <Header>
                  <h2>
                    <Link to={post.fields.slug} itemProp="url">
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h2>
                  
                </Header>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                  />
                </section>
                <Date>{post.frontmatter.date}</Date>
              </article>
            </li>
          )
        })}
      </ol>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogByLang(
    $regex: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: {
        fields: {
          slug: {
            regex: $regex
          }
        }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
          readingTime {
            text
          }
        }
        frontmatter {
          date(formatString: "MMM DD, YYYY")
          title
          description
          tags
        }
      }
    }
  }
`
// <!-- Global site tag (gtag.js) - Google Analytics -->
